import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Aiman from "./Aiman";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Articles from "./Articles";
import ArticleDetail from "./ArticleDetail";

function App() {
  return(
  <Router>
    <Navbar />
    <Footer />
    <Routes>
      <Route path="/" element={<Aiman />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/articles/:id" element={<ArticleDetail />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<Privacy />} />
    </Routes>
  </Router>
  );
}

export default App;
