import React from "react";

function Privacy() {
  return (
    <div className="page-container">
      <h1 className="page-title">Privacy Policy</h1>
      <div className="page-content">
        <ul>
          <p>
            We value your privacy. This document outlines the types of
            information we collect and how it is used. Actually we did not keep
            your cache. We only fetch your IP and geolocation and show it to
            you, so that you will know where your IP come from. Thats all, we
            don't scrap and store it. Thank you.
          </p>
        </ul>
        <p>
          For more details, please review our{" "}
          <a href="/terms" className="footer-link">
            Terms of Service
          </a>
          .
        </p>
      </div>
      <div className="page-footer">
        © {new Date().getFullYear()} AimanRemy's Website. All rights reserved.
      </div>
    </div>
  );
}

export default Privacy;
