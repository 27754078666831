import React from "react";
import { useParams } from "react-router-dom";

function ArticleDetail() {
  const { id } = useParams();

  const articles = [
    {
      id: 1,
      title: "The Beauty of Betta Fish",
      content: "Betta fish, also known as Siamese fighting fish, are one of the most beautiful freshwater fishes in the world. They come in a variety of colors, patterns, and fin shapes, making them a popular choice for aquarium enthusiasts. Proper care includes providing them with clean water, a balanced diet, and a spacious tank to thrive. Betta fish are known for their territorial nature, but with proper attention, they can become delightful pets.",
    },
    {
      id: 2,
      title: "Why Simple React Apps Are Good for Portfolios",
      content: "Creating a simple React app showcases your ability to build functional, maintainable, and visually appealing interfaces. It highlights your understanding of core concepts like component-based architecture, state management, and responsive design. Additionally, a straightforward app is easier for recruiters or collaborators to navigate and assess. It’s a great way to make a strong first impression without overwhelming your audience.",
    },
  ];

  const article = articles.find((a) => a.id === parseInt(id));

  return (
    <div className="article-detail">
      {article ? (
        <>
          <h1>{article.title}</h1>
          <p>{article.content}</p>
        </>
      ) : (
        <p>Article not found.</p>
      )}
    </div>
  );
}

export default ArticleDetail;
