import React from "react";
import { Link } from "react-router-dom";
import "./App.css";

function Articles() {
  const articles = [
    {
        id: 1,
        title: "The Beauty of Betta Fish",
        summary: "Betta fish are known for their vibrant colors and unique fin shapes...",
      },
      {
        id: 2,
        title: "Why Simple React Apps Are Good for Portfolios",
        summary: "A simple React app can effectively demonstrate your skills and creativity...",
      },
  ];

  return (
    <div className="articles-container">
      <h1>Articles</h1>
      <div className="articles-list">
        {articles.map((article) => (
          <div key={article.id} className="article-card">
            <h2>{article.title}</h2>
            <p>{article.summary}</p>
            <Link to={`/articles/${article.id}`} className="read-more">
              Read More
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Articles;
