import React from "react";
import { Link } from "react-router-dom";
import "./App.css";

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          AimanRe.my
        </Link>
        <ul className="navbar-links">
          <li>
            <Link to="/" className="navbar-link">
              Home
            </Link>
          </li>
          <li>
            <Link to="/articles" className="navbar-link">
              Articles
            </Link>
          </li>
          <li>
            <Link to="/terms" className="navbar-link">
              Terms
            </Link>
          </li>
          <li>
            <Link to="/privacy" className="navbar-link">
              Privacy
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
