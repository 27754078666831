import React from "react";
import { Link } from "react-router-dom";
import "./App.css";

function Footer() {
  return (
    <footer className="footer">
      Made with{" "}
      <span role="img" aria-label="love">
        ❤️
      </span>{" "}
      from Jitra, Kedah.
    </footer>
  );
}

export default Footer;
