import React from "react";

function Terms() {
  return (
    <div className="page-container">
      <h1 className="page-title">Terms of Service</h1>
      <div className="page-content">
        <p>
          By accessing our site, you agree to the following terms. Please read
          the following terms carefully:
        </p>
        <ul>
          <li>Being humble and kind</li>
          <li>Open minded</li>
          <li>No racist or religion terrorism</li>
          <li>Love all human beings, animals, plants and universe.</li>
          <li>You agree to use this website only for lawful purposes.</li>
          <li>
            We reserve the right to terminate your access if you violate any
            rules.
          </li>
        </ul>
      </div>
      <div className="page-footer">
        © {new Date().getFullYear()} AimanRemy's Website. All rights reserved.
      </div>
    </div>
  );
}

export default Terms;
